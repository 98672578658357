import styles from "./VectorUnderline.module.scss";

interface IVectorUnderline {
  noLeft?: boolean;
}

const VectorUnderline = ({ noLeft }: IVectorUnderline) => {
  return (
    <div
      className={styles.underline}
      style={{ borderRadius: noLeft ? "0px 25px 25px 0px" : "25px" }}></div>
  );
};

export default VectorUnderline;
