import Vector from "images/GrayVectorTop.svg";
import DumbellImage from "images/DumbellImage.webp";
import "./GymDetailsSection.scss";
import VectorUnderline from "pages/Components/VectorUnderline/VectorUnderline";
import DetailsTextContent from "pages/Components/DetailsTextContent/DetailsTextContent";
import DumbellVectorIcon from "images/DumbellVectorIcon.svg";
import FlyMachineVector from "images/FlyMachineVector.svg";
import TreadmillVector from "images/TreadmillVector.svg";

const GymDetailsSection = () => {
  return (
    <section>
      <img
        src={Vector}
        alt="Centrum sily top vector"
        className="topGrayDividerVector"
        width={"100%"}
      />
      <div className="gymDetailsContent">
        <div className="gymDetailsInnerWrapper">
          <div className="gymDetailsLeftSide">
            <div className="gymDetailsContentTitle">O siłowni</div>
            <VectorUnderline noLeft />
            <div className="gymDetailsContentDescription">
              W naszej siłowni każdy, niezależnie od płci czy poziomu
              zaawansowania, znajdzie odpowiedni sprzęt i trening. Zapraszamy
              zarówno początkujących, jak i doświadczonych entuzjastów do
              aktywnego spędzenia czasu.
            </div>
            <section className="gymDetailsContentDescriptionsSection">
              <DetailsTextContent
                text={"Strefa wolnych ciężarów"}
                icon={DumbellVectorIcon}
                description={
                  "Strefa wolnych ciężarów zawiera ławeczki, sztangi, hantle oraz różnej wagi ciężarki, Jest to obszar, gdzie ćwiczący wykonują głównie ćwiczenia siłowe."
                }
              />
              <DetailsTextContent
                text={"Strefa maszyn"}
                icon={FlyMachineVector}
                description={
                  "Strefa maszyn to miejsce wyposażone w różnorodne urządzenia treningowe, takie jak maszyny wielofunkcyjne, bramy, maszyny izolujące mięśnie. Wspomaga precyzyjny rozwój partii mięśniowych."
                }
              />
              <DetailsTextContent
                text={"Strefa kardio"}
                noMargin
                icon={TreadmillVector}
                description={
                  "Strefa kardio w siłowni zawiera bieżnie, rowerki, schody i inne maszyny aerobowe. Tutaj ćwiczący skupiają się na treningach cardio, poprawiając kondycję i wytrzymałość."
                }
              />
            </section>
          </div>
          <img
            src={DumbellImage}
            alt="DumbellImage"
            className="gymDetailsDumbellImage"
          />
        </div>
      </div>
    </section>
  );
};

export default GymDetailsSection;
