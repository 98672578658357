import useWindowDimensions from "hooks/useWindowDimensions";
import "./GalleryImage.scss";

interface IGalleryImage {
  image: string;
  alt: string;
  openModal: (item: string) => void;
}

const GalleryImage = ({ image, alt, openModal }: IGalleryImage) => {
  const { width } = useWindowDimensions();

  return (
    <div
      className="mainGalleryImageWrapper"
      onClick={() => width > 620 && openModal(image)}>
      <img src={image} alt={alt} loading="lazy" className="mainGalleryImage" />
    </div>
  );
};

export default GalleryImage;
