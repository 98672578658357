import "./BenfitCardsSection.scss";
import FitProfit from "images/fitprofit.webp";
import Medicover from "images/medicover.webp";
import Multisport from "images/multisport.webp";
import MedicoverBlue from "images/medicoverblue.webp";
import VectorUnderline from "pages/Components/VectorUnderline/VectorUnderline";
import multisportLight from "images/multisportLight.webp";
import multisportClassic from "images/multisportClassic.webp";
import multisportStudent from "images/msstudent.webp";
import multisportSenior from "images/mssenior.webp";

const BenfitCardsSection = () => {
  return (
    <div className="cardsWrapper">
      <div className="cardsWrapperTitleComponent">
        <div className="cardsWrapperTitle">Akceptujemy karty</div>
        <VectorUnderline />
      </div>
      <div className="cardsComponent">
        <img src={FitProfit} alt="Fit profit card" height={208} />
        <img src={Medicover} alt="Medicover card" height={150} />
        <img
          src={MedicoverBlue}
          alt="Medicover blue card"
          className="medicoverLightBlue"
          height={150}
        />
        <img
          src={Multisport}
          alt="Medicover blue card"
          className="medicoverLightBlue"
          height={150}
        />
        <img
          src={multisportClassic}
          alt="Medicover classic card"
          className="medicoverLightBlue"
          height={150}
        />
        <img
          src={multisportLight}
          alt="Medicover light card"
          className="medicoverLightBlue"
          height={150}
        />
        <img
          src={multisportStudent}
          alt="Medicover student"
          className="medicoverLightBlue"
          height={150}
        />
        <img
          src={multisportSenior}
          alt="Medicover senior card"
          className="medicoverLightBlue"
          height={150}
        />
      </div>
      <div className="cardsWrapperSubTitle">
        W celu rejestracji karty w naszej siłowni <u>prosimy o kontakt</u>
      </div>
    </div>
  );
};

export default BenfitCardsSection;
