import "./PassesSection.scss";
import VectorUnderline from "../../Components/VectorUnderline/VectorUnderline";
import PassComponent from "pages/Components/PassComponent/PassComponent";
import PassSmallestIcon from "images/PassSmallestIcon.svg";
import PassNightly from "images/PassNightly.svg";
import PassStudents from "images/PassStudents.svg";
import PassHeavier from "images/PassHeavier.svg";
import PassVeryHeavy from "images/PassVeryHeavy.svg";
import PassHeaviest from "images/PassHeaviest.svg";
import CommonButton from "pages/Components/CommonButton/CommonButton";

const PassesSection = () => {
  const navigateToPasses = () => {
    window.location.replace(
      "https://centrumsily.gymmanager.io/public/buy-pass",
    );
  };

  return (
    <div className="passesSectionWrapper">
      <div className="passesSectionInnerWrapper">
        <div className="passesSectionTitle">Nasz cennik</div>
        <VectorUnderline />
        <section className="passesSectionItems">
          <PassComponent
            title={"Wejście jednorazowe"}
            bottomIcon={PassSmallestIcon}
            firstPartTitle={"Koszt"}
            firstPartCost={"25 zł"}
          />
          <PassComponent
            title={"Karnet OPEN"}
            bottomIcon={PassHeavier}
            firstPartTitle={"Pierwszy raz"}
            firstPartCost={"119 zł"}
            doubleSection
            secondPartTitle="Kontynuacja"
            secondPartCost="109 zł"
          />
          <PassComponent
            title={"Zajęcia grupowe z trenerem dla kobiet"}
            bottomIcon={PassHeavier}
            firstPartTitle={"Koszt"}
            firstPartCost={"149 zł"}
            subtitle="30 DNI"
          />
          <PassComponent
            title={"Karnet OPEN + Zajęcia grupowe z trenerem dla kobiet"}
            bottomIcon={PassHeavier}
            firstPartTitle={"Koszt"}
            firstPartCost={"169 zł"}
            subtitle="30 DNI"
          />
          <PassComponent
            title={"Karnet uczniowski"}
            bottomIcon={PassStudents}
            firstPartTitle={"Pierwszy raz"}
            firstPartCost={"109 zł"}
            doubleSection
            secondPartTitle="Kontynuacja"
            secondPartCost="99 zł"
            subtitle="Osoby w wieku 15-18 lat - 30 DNI"
          />
          <PassComponent
            title={"Karnet 0:00 - 13:00"}
            bottomIcon={PassNightly}
            firstPartTitle={"Pierwszy raz"}
            firstPartCost={"99 zł"}
            doubleSection
            secondPartTitle="Kontynuacja"
            secondPartCost="89 zł"
            subtitle="30 DNI"
          />
          <PassComponent
            title={"Karnet 10 wejść"}
            bottomIcon={PassHeavier}
            firstPartTitle={"Koszt"}
            firstPartCost={"149 zł"}
            subtitle="60 DNI"
          />
          <PassComponent
            title={"Karnet 3 miesięczny"}
            bottomIcon={PassVeryHeavy}
            firstPartTitle={"Koszt"}
            firstPartCost={"299 zł"}
          />
          <PassComponent
            title={"Karnet roczny"}
            bottomIcon={PassHeaviest}
            firstPartTitle={"Koszt"}
            firstPartCost={"1099 zł"}
          />
        </section>
        <CommonButton
          text={"Kup karnet"}
          onClick={navigateToPasses}
          fontSize="34px"
          padding="4px 40px"
          marginTop="64px"
        />
      </div>
    </div>
  );
};

export default PassesSection;
