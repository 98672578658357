import VectorUnderline from "pages/Components/VectorUnderline/VectorUnderline";
import "./LocalizationSection.scss";
import GoogleMapReact from "google-map-react";
import Logo from "images/CentrumSilyLogo.svg";
interface IMarker {
  lat: number;
  lng: number;
}

const CustomLocalization = ({}: IMarker) => (
  <img
    src={Logo}
    className="localizationSectionLocalizer"
    alt="localization icon"></img>
);
const LocalizationSection = () => {
  const mapLocalizationLat = 49.6099769;
  const mapLocalizationLong = 19.1355281;
  const defaultProps = {
    center: {
      lat: mapLocalizationLat,
      lng: mapLocalizationLong,
    },
    zoom: 19,
  };
  const mapApiKey = "AIzaSyB4T_zrkArw4U-3ndkNnMVFxdef9Yov1iA"; // Replace with your actual API key
  const RenderMarkers = (map: any, maps: any) => {
    const marker = new maps.Marker({
      position: {
        lat: mapLocalizationLat,
        lng: mapLocalizationLong,
      },
      map,
      title: "Centrum siły",
    });
    return marker;
  };
  return (
    <div className="localizationSectionWrapper">
      <div className="localizationSectionTitle">Znajdziesz nas</div>
      <VectorUnderline />
      <div className="localizationSectionAddress">
        Cięcina - Dworkowa 8 nad sklepem Żabka
      </div>
      <div style={{ height: "40vh", width: "100%", marginTop: "40px" }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: mapApiKey }}
          defaultCenter={defaultProps.center}
          yesIWantToUseGoogleMapApiInternals
          defaultZoom={defaultProps.zoom}
          onGoogleApiLoaded={({ map, maps }) => RenderMarkers(map, maps)}>
          <CustomLocalization
            lat={mapLocalizationLat}
            lng={mapLocalizationLong}
          />
        </GoogleMapReact>
      </div>
    </div>
  );
};

export default LocalizationSection;
