import VectorUnderline from "pages/Components/VectorUnderline/VectorUnderline";
import "./ActivitiesCalendar.scss";
import CalendarComponent from "pages/Components/CalendarComponent/CalendarComponent";

const ActivitiesCalendar = () => {
  return (
    <div className="activitiesCalendarWrappper">
      <div className="activitiesCalendarContent">
        <div className="activitiesCalendarTitle">Kalendarz zajęć</div>
        <VectorUnderline />
        <CalendarComponent />
      </div>
    </div>
  );
};

export default ActivitiesCalendar;
