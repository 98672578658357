import CalendarTopLabel from "./CalendarTopLabel/CalendarTopLabel";
import "./CalendarComponent.scss";
import CalendarOpeningComponent from "./CalendarOpeningComponent/CalendarOpeningComponent";
import CalendarEndRowComponent from "./CalendarEndRowComponent/CalendarEndRowComponent";
import CalendarContentLabel from "./CalendarContentLabel/CalendarContentLabel";
import CalendarLastRowBorderComponent from "./CalendarLastRowBorderComponent/CalendarLastRowBorderComponent";
import useWindowDimensions from "hooks/useWindowDimensions";
import { useState } from "react";
import SelectedTileComponent from "./SelectedTileComponent/SelectedTileComponent";

export interface ICalendarTile {
  title: string;
  subtitle: string;
  position1: string;
  position2: string;
  position3: string;
  position4: string;
  position5: string;
}

const calenarTiles: ICalendarTile[] = [
  {
    title: "Trening obwodowy",
    subtitle:
      "🔵 Trening obwodowy pod okiem wykwalifikowanego trenera personalnego daje nam możliwość:",
    position1:
      "✅ Wykreowania deficytu energetycznego co za tym idzie ZREDUKOWANIA MASY CIAŁA",
    position2: "✅ Poprawy postawy ciała",
    position3: "✅ Nauczenia się poprawnej techniki ćwiczeń",
    position4: "✅ Poprawy wydolności fizycznej",
    position5: "✅ Zmniejszenia szansy na odniesienie kontuzji",
  },
];

const CalendarComponent = () => {
  const [selectedTile, setSelectedTile] = useState<ICalendarTile>(
    calenarTiles[0],
  );
  const { width } = useWindowDimensions();

  const handleCalendarTileSelection = (tile: ICalendarTile) => {
    setSelectedTile(tile);
  };

  return (
    <div className="calendarComponent">
      <div>
        {/* Titles row  */}
        <div className="calendarRow">
          <CalendarOpeningComponent text="19:30" />
          <CalendarTopLabel text={width > 768 ? "Poniedziałek" : "PON"} />
          <CalendarTopLabel text={width > 768 ? "Wtorek" : "WT"} />
          <CalendarTopLabel text={width > 768 ? "Środa" : "ŚR"} />
          <CalendarTopLabel text={width > 768 ? "Czwartek" : "CZW"} />
          <CalendarTopLabel text={width > 768 ? "Piatek" : "PT"} />
          <CalendarEndRowComponent />
        </div>
        {/* Items row */}
        <div className="calendarRow">
          <CalendarOpeningComponent text="20:30" />
          <CalendarContentLabel />
          <CalendarContentLabel
            onClick={() => handleCalendarTileSelection(calenarTiles[0])}
            text={width > 768 ? "Trening obwodowy" : "OBWÓD"}
          />
          <CalendarContentLabel />
          <CalendarContentLabel
            onClick={() => handleCalendarTileSelection(calenarTiles[0])}
            text={width > 768 ? "Trening obwodowy" : "OBWÓD"}
          />
          <CalendarContentLabel />
          <CalendarEndRowComponent />
        </div>

        <div className="calendarRow">
          <CalendarLastRowBorderComponent start />
          <CalendarLastRowBorderComponent />
          <CalendarLastRowBorderComponent />
          <CalendarLastRowBorderComponent />
          <CalendarLastRowBorderComponent />
          <CalendarLastRowBorderComponent />
          <CalendarLastRowBorderComponent end />
        </div>
      </div>
      <div className="calendarSubtitlesWrapper">
        {selectedTile == null ? (
          <span>Kliknij na zajęcia by dowiedzieć się więcej...</span>
        ) : (
          <SelectedTileComponent selectedTile={selectedTile} />
        )}
      </div>
    </div>
  );
};

export default CalendarComponent;
