import "./CommonButton.scss";

interface ICommonButton {
  text: string;
  marginTop?: string;
  marginBottom?: string;
  onClick: () => void;
  padding?: string;
  fontSize?: string;
}

const CommonButton = ({
  text,
  onClick,
  marginTop,
  marginBottom,
  padding,
  fontSize,
}: ICommonButton) => {
  return (
    <div
      onClick={onClick}
      className="commonButtonStyles"
      style={{
        marginTop: marginTop,
        marginBottom: marginBottom,
        padding: padding ? padding : "4px 40px",
        fontSize: fontSize ? fontSize : "20px",
      }}>
      {text}
    </div>
  );
};

export default CommonButton;
