import CommonButton from "pages/Components/CommonButton/CommonButton";
import "./MainScreen.scss";
import BodyBuilderImage from "images/MainImage.webp";
import GooglePlayButton from "images/GooglePlayLogo.webp";
import AppleStoreButton from "images/AppleStoreLogo.webp";

interface IMainScreen {
  headerAnimated: boolean;
}

const MainScreen = ({ headerAnimated }: IMainScreen) => {
  const navigateToPortal = () => {
    window.location.replace("https://centrumsily.gymmanager.io");
  };

  const navigateToGooglePlayStore = () => {
    window.location.replace("https://gymmanager.io/centrum-sily/");
  };

  const navigateToAppleStore = () => {
    window.location.replace("https://gymmanager.io/centrum-sily/");
  };

  return (
    <div className="mainPageScreen">
      <div className="mainPageInnerWrapper">
        <div className="mainPageScreenText">
          <div
            className={`mainPageWhiteColor ${
              !headerAnimated ? "textOpacityAnimate animateText1" : ""
            }`}>
            Wzmacniaj ciało.
          </div>
          <div
            className={`mainPageWhiteColor ${
              !headerAnimated ? "textOpacityAnimate animateText2" : ""
            }`}>
            Buduj moc.
          </div>
          <div
            className={`mainPageRedColor ${
              !headerAnimated ? "textOpacityAnimate animateText3" : ""
            }`}>
            Centrum Siły.
          </div>
          <div
            className={`mainPageRedColorSmaller ${
              !headerAnimated ? "textOpacityAnimate animateText4" : ""
            }`}>
            Siłowania 24/7
          </div>
          <div
            className={`mainPageRedColorVerySmall ${
              !headerAnimated ? "textOpacityAnimate animateText4" : ""
            }`}>
            Honorujemy karty: Medicover / FitProfit / Mulitsport
          </div>
          <div
            className={`${
              !headerAnimated ? "textOpacityAnimate animateText5 " : ""
            }`}>
            <CommonButton
              text="Dołącz do nas"
              marginTop="40px"
              onClick={navigateToPortal}
            />
            <div className="titleBottomButtons">
              <img
                src={GooglePlayButton}
                alt="Google play button"
                height={"60px"}
                className="applicationButton"
                onClick={navigateToGooglePlayStore}
              />
              <img
                src={AppleStoreButton}
                alt="Apple store button"
                height={"60px"}
                className="applicationButton"
                onClick={navigateToAppleStore}
              />
            </div>
          </div>
        </div>
        <img
          src={BodyBuilderImage}
          alt={"Centrum siły lifter"}
          className="mainScreenBodyBuilderImage"
        />
      </div>
    </div>
  );
};

export default MainScreen;
