import { ICalendarTile } from "../CalendarComponent";
import "./SelectedTileComponent.scss";

interface ISelectedTileComponent {
  selectedTile: ICalendarTile;
}

const SelectedTileComponent = ({ selectedTile }: ISelectedTileComponent) => {
  return (
    <div>
      <div className="selectedTileComponentTitle">{selectedTile.title}</div>
      <div className="selectedTileText">{selectedTile.subtitle}</div>
      <div className="selectedTileText">{selectedTile.position1}</div>
      <div className="selectedTileText">{selectedTile.position2}</div>
      <div className="selectedTileText">{selectedTile.position3}</div>
      <div className="selectedTileText">{selectedTile.position4}</div>
      <div className="selectedTileText">{selectedTile.position5}</div>
    </div>
  );
};

export default SelectedTileComponent;
