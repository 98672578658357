import image1 from "images/Gallery/1.webp";
import image2 from "images/Gallery/2.webp";
import image3 from "images/Gallery/3.webp";
import image4 from "images/Gallery/4.webp";
import image5 from "images/Gallery/5.webp";
import image6 from "images/Gallery/6.webp";
import image7 from "images/Gallery/7.webp";
import image8 from "images/Gallery/8.webp";
import image9 from "images/Gallery/9.webp";
import image10 from "images/Gallery/10.webp";
import image11 from "images/Gallery/11.webp";
import image12 from "images/Gallery/12.webp";
import image13 from "images/Gallery/13.webp";
import image14 from "images/Gallery/14.webp";
import image15 from "images/Gallery/15.webp";
import image16 from "images/Gallery/16.webp";
import image17 from "images/Gallery/17.webp";
import image18 from "images/Gallery/18.webp";
import image19 from "images/Gallery/19.webp";
import "./MainGallery.scss";
import Modal from "react-modal";
import { useState } from "react";
import GalleryImage from "pages/Components/GalleryImage/GalleryImage";

const MainGallery = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [currentPicture, setCurrentPicture] = useState<string>("");

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openModal = (itemPicture: string) => {
    setIsModalOpen(true);
    setCurrentPicture(itemPicture);
  };

  return (
    <div className="mainGalleryGrid">
      <Modal
        isOpen={isModalOpen}
        className={"galleryModalContainer"}
        overlayClassName="galleryModalOverlay"
        onRequestClose={closeModal}
        ariaHideApp={false}
        contentLabel="Example Modal">
        {isModalOpen && currentPicture && (
          <div>
            <div className="galleryAdditionalExit" onClick={closeModal}>
              X
            </div>
            <img
              src={currentPicture}
              loading="lazy"
              className="galleryBigPicture"
              alt="Big screen centrum siły gallery picture"
            />
          </div>
        )}
      </Modal>
      <GalleryImage
        image={image1}
        alt={"Centrum sily gym image 1"}
        openModal={openModal}
      />
      <GalleryImage
        image={image2}
        alt={"Centrum sily gym image 2"}
        openModal={openModal}
      />
      <GalleryImage
        image={image3}
        alt={"Centrum sily gym image 3"}
        openModal={openModal}
      />
      <GalleryImage
        image={image4}
        alt={"Centrum sily gym image 4"}
        openModal={openModal}
      />
      <GalleryImage
        image={image5}
        alt={"Centrum sily gym image 5"}
        openModal={openModal}
      />
      <GalleryImage
        image={image6}
        alt={"Centrum sily gym image 6"}
        openModal={openModal}
      />
      <GalleryImage
        image={image7}
        alt={"Centrum sily gym image 7"}
        openModal={openModal}
      />
      <GalleryImage
        image={image8}
        alt={"Centrum sily gym image 8"}
        openModal={openModal}
      />
      <GalleryImage
        image={image9}
        alt={"Centrum sily gym image 9"}
        openModal={openModal}
      />
      <GalleryImage
        image={image10}
        alt={"Centrum sily gym image 10"}
        openModal={openModal}
      />
      <GalleryImage
        image={image11}
        alt={"Centrum sily gym image 11"}
        openModal={openModal}
      />
      <GalleryImage
        image={image12}
        alt={"Centrum sily gym image 12"}
        openModal={openModal}
      />
      <GalleryImage
        image={image13}
        alt={"Centrum sily gym image 13"}
        openModal={openModal}
      />
      <GalleryImage
        image={image14}
        alt={"Centrum sily gym image 14"}
        openModal={openModal}
      />
      <GalleryImage
        image={image15}
        alt={"Centrum sily gym image 15"}
        openModal={openModal}
      />
      <GalleryImage
        image={image16}
        alt={"Centrum sily gym image 16"}
        openModal={openModal}
      />
      <GalleryImage
        image={image17}
        alt={"Centrum sily gym image 17"}
        openModal={openModal}
      />
      <GalleryImage
        image={image18}
        alt={"Centrum sily gym image 18"}
        openModal={openModal}
      />
      <GalleryImage
        image={image19}
        alt={"Centrum sily gym image 19"}
        openModal={openModal}
      />
    </div>
  );
};

export default MainGallery;
