import "./CalendarOpeningComponent.scss";

interface ICalendarOpeningComponent {
  text: string;
}

const CalendarOpeningComponent = ({ text }: ICalendarOpeningComponent) => {
  return (
    <div className="calendarRowOpeningComponent">
      <div className="calendarRowOpeningComponentText">{text}</div>
    </div>
  );
};

export default CalendarOpeningComponent;
