import Header from "pages/MainPage/Header/Header";
import "./App.scss";
import MainScreen from "pages/MainPage/MainScreen/MainScreen";
import GymDetailsSection from "pages/MainPage/GymDetailsSection/GymDetailsSection";
import PassesSection from "pages/MainPage/PassesSection/PassesSection";
import LocalizationSection from "pages/MainPage/LocalizationSection/LocalizationSection";
import GymEntranceInstruction from "pages/MainPage/GymEntranceInstruction/GymEntranceInstruction";
import Footer from "pages/MainPage/Footer/Footer";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import MainGallery from "pages/MainGalleryPage/MainGallery/MainGallery";
import MobileMenu from "pages/Components/MobileMenu/MobileMenu";
import ScrollTop from "hooks/ScrollTop";
import NotFoundPage from "pages/NotFoundPage/NotFoundPage";
import StatuteMain from "pages/StatutePage/StatuteMain";
import { useState } from "react";
import ActivitiesCalendar from "pages/MainPage/ActivitiesCalendar/ActivitiesCalendar";
import BenfitCardsSection from "pages/MainPage/BenfitCardsSection/BenfitCardsSection";

const App = () => {
  const [headerAnimated, setHeaderAnimated] = useState<boolean>(false);
  const headerWasAnimated = () => {
    setHeaderAnimated(true);
  };

  return (
    <div className="App">
      <div className="WebApp">
        <BrowserRouter>
          <ScrollTop>
            <Routes>
              <Route
                path="/"
                element={
                  <>
                    <Header
                      headerAnimated={headerAnimated}
                      headerWasAnimated={headerWasAnimated}
                    />
                    <MainScreen headerAnimated={headerAnimated} />
                    <GymDetailsSection />
                    <PassesSection />
                    <BenfitCardsSection />
                    <ActivitiesCalendar />
                    <LocalizationSection />
                    <GymEntranceInstruction />
                    <Footer />
                    <MobileMenu />
                  </>
                }
              />
              <Route
                path="/gallery"
                element={
                  <div>
                    <Header
                      headerAnimated={headerAnimated}
                      headerWasAnimated={headerWasAnimated}
                    />
                    <MainGallery />
                    <Footer />
                    <MobileMenu />
                  </div>
                }
              />
              <Route
                path="/statute"
                element={
                  <div>
                    <Header
                      headerAnimated={headerAnimated}
                      headerWasAnimated={headerWasAnimated}
                    />
                    <StatuteMain />
                    <Footer />
                    <MobileMenu />
                  </div>
                }
              />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </ScrollTop>
        </BrowserRouter>
      </div>
    </div>
  );
};

export default App;
