import "./StatuteMain.scss";
import CommonButton from "pages/Components/CommonButton/CommonButton";
import Pdf from "files/Doc.pdf";
import Word from "files/DocWord.docx";
import useWindowDimensions from "hooks/useWindowDimensions";

const StatuteMain = () => {
  const { width } = useWindowDimensions();

  const downloadDocumentPdf = () => {
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.download = "Oświadczenie rodzica PDF";
    a.href = Pdf;
    a.click();
  };

  const downloadDocumentWord = () => {
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.download = "Oświadczenie rodzica WORD";
    a.href = Word;
    a.click();
  };

  return (
    <div className="statuteMain">
      <div className="statueMainText">Regulamin</div>
      <div className="statueDescription">
        1. Centrum Siły jest dostępne dla klubowiczów przez całą dobę, 7 dni w
        tygodniu. Z siłowni mogą korzystać osoby, które posiadają ważny karnet
        lub wykupiły jednorazowe wejście. Minimalny wiek uczestnictwa w Centrum
        Siły to 15 lat. Osoby w wieku poniżej 18 lat zobowiązane są przed
        pierwszym wejściem na siłownię dostarczyć pisemne oświadczenie od
        rodzica/opiekuna prawnego, zezwalające na uczestnictwo w treningach.
        Oświadczenia można dostarczyć personelowi siłowni od poniedziałku do
        piątku w godzinach 18.00-19.30 lub w innym terminie po wcześniejszym
        uzgodnieniu (Leszek Pacyna 693063256, Joanna Jasek-Pacyna 507041253).
        Oświadczenie jest dostępne poniżej lub w wersji papierowej przed
        wejściem na siłownię. W przypadku braku oświadczenia karnet do klubu
        może zostać unieważniony, bez zwrotu poniesionych kosztów.
        <div className="statueMainButtons">
          <CommonButton
            text={`${
              width > 1270
                ? "Pobierz oświadczenie rodzica (Word)"
                : width > 605
                ? "Oświadczenie WORD"
                : "WORD"
            }`}
            marginTop="40px"
            marginBottom="40px"
            onClick={downloadDocumentWord}
          />
          <CommonButton
            text={`${
              width > 1270
                ? "Pobierz oświadczenie rodzica (PDF)"
                : width > 605
                ? "Oświadczenie PDF"
                : "PDF"
            }`}
            marginTop="40px"
            marginBottom="40px"
            onClick={downloadDocumentPdf}
          />
        </div>
      </div>

      <div className="statueDescription">
        2. Osoby będące w obiekcie po raz pierwszy zobowiązane są dokonać
        rejestracji oraz opłaty na urządzeniach elektronicznych, zapoznać się z
        treścią niniejszego Regulaminu i potwierdzić ten fakt poprzez
        zaznaczenie w formularzu elektronicznym odpowiedniego oświadczenia.
        Przejście przez bramkę kołowrotkową umożliwi nam zeskanowanie kodu QR,
        który znajduje się na koncie klienta. Przez bramkę kołowrotkową należy
        przechodzić we właściwy sposób. CENTRUM SIŁY nakłada na każdą osobę
        przechodząca przez ww. system w sposób niewłaściwy (ominięcie,
        przeskoczenie itp.) karę w wysokości 5000zł oraz dożywotni zakaz wstępu
        do klubu. Akty wandalizmu będą rozstrzygane na drodze sądowej.
      </div>

      <div className="statueDescription">
        3. Karnet wystawiony na dany miesiąc lub w odpowiednich ramach czasowych
        /od-do/ musi być wykorzystany w danym miesiącu lub określonych ramach
        czasowych, nie ma możliwości jego przedłużenia /niezależnie od zdarzeń
        losowych czy sytuacji osobistej klienta/.
      </div>

      <div className="statueDescription">
        4. Niewykorzystany karnet przepada i nie należy się za niego zwrot
        pieniędzy.
      </div>

      <div className="statueDescription">
        5. Karnety wystawiane są imiennie, tylko na jedną osobę, nie ma
        możliwości ich odstępowania innym osobom. W przypadku stwierdzenia, że
        użytkownikiem karnetu nie jest osoba, na którą został wystawiony CENTRUM
        SIŁY zastrzega sobie prawo do jego anulowania bez zwrotu poniesionych
        kosztów oraz niewykorzystanej części karnetu. Klienci są zobowiązani do
        posiadania przy sobie dowodu tożsamości ze zdjęciem za każdym razem
        kiedy wchodzą do placówki Klubu. Personel Klubu może poprosić Klienta o
        przedstawienie dowodu tożsamości, a w razie odmowy z jakichkolwiek
        powodów, Personel Klubu może nakazać danemu Klientowi natychmiastowe
        opuszczenie placówki Klubu.
      </div>

      <div className="statueDescription">
        6. Każdy Klubowicz zobowiązany jest do posiadania własnej kłódki do
        szafki ubraniowej. W celu umieszczenia wszystkich przyniesionych do
        Klubu rzeczy, w tym toreb, plecaków, odzieży wierzchniej, czy też
        obuwia. Poza szafkami nie powinny być przechowywane żadne rzeczy
        członków Klubu. Klient jest zobowiązany do opróżnienia szafki po
        zakończonym treningu. W przypadku pozostawienia rzeczy w szafce
        ubraniowej po opuszczeniu Klubu, zastrzegamy sobie prawo do rozcięcia
        kłódki i usunięcia pozostawionych rzeczy oraz opróżnienia szafki.
        CENTRUM SIŁY nie odpowiada za przedmioty pozostawione na terenie całego
        obiektu.
      </div>

      <div className="statueDescription">
        7. Klienci oraz Członkowie Klubu zobowiązani są do: a) Każda osoba
        korzystająca ze sprzętu na siłowni, a w szczególności z wolnych
        ciężarów, hantli, sztang i talerzy sprząta po sobie i odnosi na właściwe
        miejsce używane podczas ćwiczeń przedmioty; b) Sprzęt powinien być
        używany zgodnie z instrukcjami i przeznaczeniem producenta; c)
        Niedopuszczalne jest modyfikowanie lub uszkadzanie sprzętu; d)
        Powstrzymania się od działań, które mogą zakłócać lub utrudniać
        korzystanie z usług Klubu innym Klientom, skutkować uszkodzeniem,
        zniszczeniem mienia lub pomieszczeń Klubu, zakłócać pracownikom Klubu
        wypełnianie ich obowiązków; e) Poszanowania mienia Klubu; f)
        Przestrzegania ogólnie przyjętych norm zachowania ( nie używać
        wulgaryzmów, umiarkowany ton głosu); g) Zabrania się odsłaniania ciała
        na terenie stref ćwiczących; h) Posiadania i używania osobistego
        ręcznika.
      </div>
      <div className="statueDescription">
        8. W klubie bezwzględnie obowiązuje obuwie zamienne – z czystą podeszwą,
        inne od tego, w którym się przyszło.
      </div>

      <div className="statueDescription">
        9. Na terenie Klubu zabrania się; a) wnoszenia i spożywania napojów
        alkoholowych, a także wstępu do Klubu pod wpływem tych środków; b)
        używania tytoniu, e-paopierosów. c) używania środków z zawartością
        sterydów, środków odurzających, narkotyków, a także wstępu do Klubu pod
        wpływem tych środków; d) wprowadzania zwierząt; e) wprowadzania na
        terenie klubu roweru, hulajnogi i deskorolki itp. f) handlu, akwizycji
        oraz prowadzenia jakiejkolwiek działalności, celem której jest osiąganie
        bezpośrednio lub pośrednio korzyści majątkowych, bądź niemajątkowych pod
        rygorem rozwiązania przez Klub umowy ze skutkiem natychmiastowym z winy
        Członka Klubu oraz obciążenia Klubowicza karą umowną stanowiącą
        równowartość trzech opłat miesięcznych; g) naklejania i pozostawiania
        reklam bez zgody właścicieli Klubu.
      </div>

      <div className="statueDescription">
        10. CENTRUM SIŁY nie ponosi odpowiedzialności za wypadki będące wynikiem
        korzystania z maszyn i urządzeń niezgodnie z ich przeznaczeniem.
      </div>

      <div className="statueDescription">
        11. Osoby korzystające z siłowni zobowiązane są do zabezpieczania
        sztangi zaciskami.
      </div>

      <div className="statueDescription">
        12. Nie wolno kłaść hantli i sztang na tapicerce. Miejsce ich jest na
        podłodze lub specjalnych uchwytach.
      </div>

      <div className="statueDescription">
        13. Zakaz używania magnezji w formie proszkowej.
      </div>

      <div className="statueDescription">
        14. CENTRUM SIŁY jest obiektem monitorowanym.
      </div>

      <div className="statueDescription">
        15. W CENTRUM SIŁY wykonywane są okazjonalnie zdjęcia fotograficzne.
        Zdjęcia te zostaną wykorzystane na potrzeby klubu, tj. facebook,
        Istagram jako galeria lub kolekcja zdjęć pamiątkowych.
      </div>

      <div className="statueDescription">
        16. Wszelkie stwierdzone przypadki zniszczenia, dewastacji urządzeń,
        kradzieży lub zgubienie elementów podlegają odpłatności w kwocie 100%
        wartości.
      </div>

      <div className="statueDescription">
        17. Zmiany regulaminu, oraz cennika Klubu podaje się do wiadomości po
        przez ogłoszenie na stronie internetowej centrumsily.com.
      </div>

      <div className="statueDescription">
        18. W przypadku niestosowania się do postanowień niniejszego regulaminu,
        karnet do klubu może zostać unieważniony, bez zwrotu poniesionych
        kosztów oraz niewykorzystanej części karnetu.
      </div>

      <div className="statueDescription">
        19. W razie pytań lub zastrzeżeń dotyczących regulaminu, prosimy o
        kontakt z personelem Centrum Siły - (Leszek Pacyna 693063256, Joanna
        Jasek-Pacyna 507041253).
      </div>
    </div>
  );
};

export default StatuteMain;
