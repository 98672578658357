import CommonButton from "pages/Components/CommonButton/CommonButton";
import "./NotFoundPage.scss";
import { useNavigate } from "react-router-dom";

const NotFoundPage = () => {
  const navigate = useNavigate();
  const navigateToMainPage = () => {
    navigate("/");
  };
  return (
    <div className="notFoundPage">
      <div className="notFoundPageMainTitle">404 - Brak Ciężarów!</div>
      <div className="notFoundPageDescription">
        Hmm, wygląda na to, że te ciężary zostały przeniesione gdzieś indziej.
      </div>
      <CommonButton
        text={"Powrót"}
        onClick={navigateToMainPage}
        fontSize="34px"
        padding="4px 40px"
        marginTop="32px"
      />
    </div>
  );
};

export default NotFoundPage;
