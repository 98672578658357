import VectorUnderline from "../VectorUnderline/VectorUnderline";
import "./PassComponent.scss";
interface IPassComponent {
  title: string;
  bottomIcon: string;
  firstPartTitle: string;
  firstPartCost: string;
  doubleSection?: boolean;
  secondPartTitle?: string;
  secondPartCost?: string;
  subtitle?: string;
}

const PassComponent = ({
  title,
  bottomIcon,
  firstPartCost,
  firstPartTitle,
  doubleSection,
  secondPartCost,
  secondPartTitle,
  subtitle,
}: IPassComponent) => {
  return (
    <section className="passComponent">
      <header className="passComponentTitleWrapper">
        <div className="passComponentTitle">{title}</div>
      </header>
      <main className="passComponentMain">
        <div className="passComponentTopItems">
          <div className="passComponentMainFirstPartTitle">
            {firstPartTitle}
          </div>
          <div className="passComponentMainFirstPartCost">{firstPartCost}</div>
          {doubleSection && <VectorUnderline />}
          <div className="passComponentMainSecondPartTitle">
            {secondPartTitle}
          </div>
          <div className="passComponentMainSecondPartCost">
            {secondPartCost}
          </div>
        </div>
        {subtitle ? (
          <div className="passComponentMainSubtitle">{subtitle}</div>
        ) : (
          <div className="passComponentSubtitleSpace"></div>
        )}
        <div style={{ height: "70px", marginTop: "8px" }}>
          <img src={bottomIcon} alt={`Bottom icon ${title}`} />
        </div>
      </main>
      <footer>
        <div className="bottomVectorRed" />
      </footer>
    </section>
  );
};

export default PassComponent;
