import HeaderVector from "images/HeaderVector.svg";
import CentrumSilyLogo from "images/CentrumSilyLogo.svg";
import "./Header.scss";
import { Link, useLocation } from "react-router-dom";
import BurgerMenu from "images/BurgerMenuCentrumSily.svg";
import useClickedOutsideElement from "hooks/useClickedOutsideElement";

interface IHeader {
  headerAnimated: boolean;
  headerWasAnimated: () => void;
}

const Header = ({ headerAnimated, headerWasAnimated }: IHeader) => {
  const { isDropdownOpen, handleDropdown, menuRef, menuBtnRef } =
    useClickedOutsideElement();
  const location = useLocation();
  const navigateToPortal = () => {
    window.location.replace("https://centrumsily.gymmanager.io");
  };

  const openTabletMenu = () => {
    handleDropdown(true);
  };

  const closeTabletMenu = () => {
    handleDropdown(false);
  };

  return (
    <header
      className={`${
        !headerAnimated
          ? "animateHeader topHeader"
          : "headerOpacityNormal topHeader"
      }`}
      onAnimationEnd={headerWasAnimated}>
      <div>
        <img
          src={HeaderVector}
          alt="Top Header Vector"
          className="topHeaderVector"
        />
        <div className="topHeaderTextPositions">
          <div className="topHeaderTextItems">
            <Link to="/" className="topHeaderTextItem">
              Strona Główna
            </Link>
            <Link to="/gallery" className="topHeaderTextItem">
              Galeria
            </Link>
            <img
              src={CentrumSilyLogo}
              alt="Centrum siły logo"
              className="topHeaderVectorLogo"
            />
            <Link to="/statute" className="topHeaderTextItem">
              Regulamin
            </Link>
            <div className="topHeaderTextItem" onClick={navigateToPortal}>
              Portal
            </div>
          </div>
        </div>
        <div className="topHeaderTabletMenu">
          <img
            ref={menuBtnRef}
            src={BurgerMenu}
            alt="Burger Menu"
            height={"35px"}
            className="burgerMenuStyle"
            onClick={openTabletMenu}
          />
          {isDropdownOpen && (
            <div
              className="topHeaderTabletMenuContainer"
              ref={menuRef}
              onClick={closeTabletMenu}>
              {location.pathname == "/gallery" ? (
                <Link to="/" className="topHeaderTabletMenuItem">
                  Strona Główna
                </Link>
              ) : (
                <Link to="/gallery" className="topHeaderTabletMenuItem">
                  Galeria
                </Link>
              )}
              <Link to="/statute" className="topHeaderTabletMenuItem">
                Regulamin
              </Link>
              <div
                className="topHeaderTabletMenuItem"
                onClick={navigateToPortal}>
                Portal
              </div>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
