import "./CalendarLastRowBorderComponent.scss";

interface ICalendarLastRowBorderComponent {
  start?: boolean;
  end?: boolean;
}

const CalendarLastRowBorderComponent = ({
  start,
  end,
}: ICalendarLastRowBorderComponent) => {
  return (
    <div
      className={`calendarComponentLastRowBorder ${
        start
          ? "calendarComponentLastRowBorderStart"
          : end
          ? "calendarComponentLastRowBorderEnd"
          : "calendarComponentLastRowBorderWidth"
      }`}
      style={{
        borderLeft: start ? "none" : "1px dashed #474747",
      }}
    />
  );
};

export default CalendarLastRowBorderComponent;
