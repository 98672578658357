import "./DetailsTextContent.scss";

interface IDetailsTextContent {
  text: string;
  icon: string;
  description: string;
  noMargin?: boolean;
}

const DetailsTextContent = ({
  text,
  icon,
  description,
  noMargin,
}: IDetailsTextContent) => {
  return (
    <div
      className="detailsTextContentWrapper"
      style={{ marginRight: noMargin ? "0" : "54px" }}>
      <div className="detailsTextContentHeightWrapper">
        <img src={icon} alt="Centrum sily dumbell vector" />
      </div>
      <div className="detailsTextContentTitle">{text}</div>
      <div className="detailsTextContentDescription">{description}</div>
    </div>
  );
};

export default DetailsTextContent;
