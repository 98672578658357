import "./CalendarContentLabel.scss";

interface ICalendarContentLabel {
  text?: string;
  onClick?: () => void;
}

const CalendarContentLabel = ({ text, onClick }: ICalendarContentLabel) => {
  return (
    <div className="calendarContentWrapper" onClick={onClick}>
      {text ? (
        <div className="calendarContentLabel">{text}</div>
      ) : (
        <div className="calendarTopLabelEmpty"></div>
      )}
    </div>
  );
};

export default CalendarContentLabel;
