import "./CalendarTopLabel.scss";

interface ICalendarTopLabel {
  text: string;
}

const CalendarTopLabel = ({ text }: ICalendarTopLabel) => {
  return (
    <div className="calendarTopLabelWrapper">
      <div className="calendarTopLabel">{text}</div>
    </div>
  );
};

export default CalendarTopLabel;
